import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ModificadorProducto} from '../../services/dto/orden-indirecta';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import {completarItemComanda} from '../../redux/slices/comanda-slice/comanda.thunk';
import {useAppDispatch} from '../../redux/store';

type PropsTypes = {
  name: string;
  cantidad: number;
  observacion?: string;
  isCanceled: boolean;
  modificadores: ModificadorProducto[];
  id_codigo_orden: number;
  id_orden_producto_menu: number;
};

const ComandaPlatoItem = ({
  name,
  cantidad,
  observacion,
  isCanceled,
  modificadores,
  id_codigo_orden,
  id_orden_producto_menu,
}: PropsTypes) => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useAppDispatch();
  const isHistorial = useLocation().pathname === '/historial-comandas';
  const [loading, setLoading] = useState(false);

  const handleClickCompleted = async () => {
    setLoading(true);
    handleOnScratch();
    if (!toggle)
      await dispatch(
        completarItemComanda({
          id_codigo_orden,
          id_orden_producto_menu,
          estado_orden_id: 1,
        }),
      );
    setLoading(false);
  };
  const typeOfLineThrough = (): string => {
    if (isCanceled) return 'line-through';
    if (toggle) return 'line-through';
    return 'none';
  };

  const colorChangeToggle = (): string | undefined => {
    if (isCanceled) return 'red';
    if (toggle) return 'gray';
    return undefined;
  };

  const handleOnScratch = (): void => {
    if (isHistorial) return;
    if (!isCanceled) {
      setToggle(prev => !prev);
    }
  };

  return (
    <Box
      sx={{
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        cursor: 'pointer',
        justifyContent: 'space-between',
        mb: 1,
        '&:last-child': {
          mb: 0,
        },
      }}>
      <Box
        onClick={handleOnScratch}
        sx={{
          display: 'flex',
          width: '90%',
          justifyContent: 'space-around',
        }}>
        <Stack sx={{maxWidth: '90%'}}>
          <Typography
            sx={{
              position: 'relative',
              display: 'inline-block',
              fontSize: '0.9rem',
              fontWeight: 'bold',

              lineHeight: 1,
              color: colorChangeToggle(),
              '&:after': {
                content: '""',
                position: 'absolute',
                border: '3px solid #000',
                top: '50%',
                marginTop: '-3px',
                width: '100%',
                left: '-2px',
                display:
                  typeOfLineThrough() === 'line-through'
                    ? 'inline-block'
                    : 'none',
              },
            }}>
            {name}
          </Typography>
          {modificadores.map(modificador => {
            if (!modificador.modificador_menu) return <></>;
            const nombre =
              modificador.modificador_menu.accion_asignada_modificador
                .modificador.nombre_modificador;
            const accion =
              modificador.modificador_menu.accion_asignada_modificador.accion
                .nombre_accion_modificador;
            return (
              <Typography
                key={modificador.id_modificador_p_ordenado}
                sx={{
                  color: toggle ? 'grey' : 'black',
                  fontSize: '0.6rem',
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                  lineHeight: 1,
                  textDecoration: toggle ? 'line-through' : 'none',
                }}>
                {`${accion} - ${nombre}`}
              </Typography>
            );
          })}
          <Typography
            sx={{
              color: toggle ? 'pink' : 'red',
              fontSize: '0.6rem',
              fontWeight: 'bold',
              fontStyle: 'italic',
              lineHeight: 1,
              textDecoration: toggle ? 'line-through' : 'none',
            }}>
            {isCanceled ? '' : observacion}
          </Typography>
        </Stack>
        <Box sx={{flexGrow: 1}} />
        <Typography
          noWrap
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            minWidth: '20px',
          }}>
          {cantidad}
        </Typography>
      </Box>
      {!isHistorial ? (
        <Box width={'10%'}>
          <Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <RoomServiceIcon onClick={handleClickCompleted}></RoomServiceIcon>
            )}
          </Typography>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default ComandaPlatoItem;
