import * as React from 'react';
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {EntidadReceptora} from '../../../../redux/slices/orden-slice/orden.types';
import SearchIcon from '@mui/icons-material/Search';
import {getEntidadReceptora} from '../../../../redux/slices/orden-slice/orden.thunks';
import {useAppDispatch} from '../../../../redux/store';

type propsType = {
  tipo_receptor_id: number;
  handleReduxUpdate?: any | null;
  selectEntidadReceptora?: any | null;
  updateRedux?: Boolean;
};

const RucAutocomplete = function ({
  tipo_receptor_id,
  handleReduxUpdate,
  selectEntidadReceptora,
  updateRedux = false,
}: propsType) {
  const dispatch = useAppDispatch();

  const [value, setValue] = React.useState<EntidadReceptora | null>(null);
  const [loading, setLoading] = React.useState<Boolean>(false);
  const [suggestionsEnditadReceptora, setSuggestionsEnditadReceptora] =
    React.useState<EntidadReceptora[]>([]);

  ///Fetch
  const fetchEntidadReceptoraByMatch = async (buscador: any) => {
    /// If the param is over 3 characters.
    if (buscador.length > 3) {
      await dispatch(getEntidadReceptora({tipo_receptor_id, buscador}))
        .unwrap()
        .then(async x => {
          setSuggestionsEnditadReceptora([...x]); ///Set list of EntidadReceptora state
          ///docs: https://dgi.mef.gob.pa/Ruc/Ruc.php
          if (tipo_receptor_id == 1 || tipo_receptor_id == 3) {
            // RUC para los registros mercantiles estaría compuesto de la
            // siguiente manera: FOLIO-2-AÑO, donde el folio se inicia con nueve (9)
            // dígitos, del tipo numérico
            if (buscador.length > 8) {
              if (suggestionsEnditadReceptora.length == 0) {
                /// Si no hay coincidencias y ya escribio el FOLIO
                // actualizamos el state del componente padre con
                // una entidad receptora inicializada con los
                // valores del input...
                selectEntidadReceptora({
                  tipo_receptor_id: tipo_receptor_id.toString(),
                  ruc_receptor: {
                    ruc: buscador,
                    dv: 0,
                    name: '',
                  },
                });
              }
            }
          }
        })
        .catch((e: any) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  return (
    <Autocomplete
      filterOptions={x => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value} ///value es un ibjeto de tipo el objeto que va a montar en el resultado
      noOptionsText="No hemos encontrado registros."
      getOptionLabel={option => `${option.ruc_receptor?.ruc}`}
      isOptionEqualToValue={(option, value1) =>
        option.ruc_receptor?.ruc === value1.ruc_receptor?.ruc
      }
      onChange={(event: any, newValue: EntidadReceptora | null) => {
        //setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        ///here put handle redux update and form filled
        if (newValue) {
          if (updateRedux) {
            handleReduxUpdate(newValue);
          }
          selectEntidadReceptora(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        fetchEntidadReceptoraByMatch(newInputValue);
      }}
      renderInput={params => (
        <TextField
          label="RUC/Nombre"
          // placeholder={'00000-000-000'}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
                <SearchIcon />
              </React.Fragment>
            ),
          }}
        />
      )}
      options={suggestionsEnditadReceptora} ///esto debe cargarse con el request
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid
                item
                sx={{
                  width: 'calc(100% - 44px)',
                  wordWrap: 'break-word',
                }}>
                <Typography variant="body2" color="text.secondary">
                  {`${option.ruc_receptor?.name} - ${option.ruc_receptor?.ruc} DV ${option.ruc_receptor?.dv}`}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default RucAutocomplete;
