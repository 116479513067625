import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.locale('es');
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const dateFromNow = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
) => dayjs(date).fromNow();

export const localizedFormatDate = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
) => dayjs(date).format('LLLL');

export const formatDateTime = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  utc = false,
) => {
  if (utc) return dayjs.utc(date).local().format('DD/MM/YYYY hh:mm a');
  return dayjs(date).format('DD/MM/YYYY hh:mm a');
};

export const formatDate = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  utc = false,
) => {
  if (utc) return dayjs.utc(date).local().format('DD/MM/YYYY');
  return dayjs(date).format('DD/MM/YYYY');
};

export const DBtoDate = (date: string | Date, ampm: boolean = false) => {
  return dayjs
    .utc(date, ampm ? 'YYYY-MM-DD HH:mm a' : 'YYYY-MM-DD HH:mm')
    .toDate();
};

export const getTimer = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
): string => {
  if (!date) return '00:00';
  const now = dayjs();
  const then = dayjs(date);

  let timer = dayjs.utc(dayjs(now).diff(dayjs(then)));
  if (timer.valueOf() < 0) timer = dayjs.utc(0);

  const day = timer.date() - 1;

  const seconds = timer.second() <= 9 ? `0${timer.second()}` : timer.second();
  const minutes = timer.minute() <= 9 ? `0${timer.minute()}` : timer.minute();
  const hours = timer.hour() <= 9 ? `0${timer.hour()}` : timer.hour();
  const days = day <= 9 ? `0${day}` : day;

  return `${days > 0 ? `${days}:` : ''}${
    hours > 0 ? `${hours}:` : ''
  }${minutes}:${seconds}`;
};

export const daysOfTheWeek = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo',
];
