import {ApiResponse} from 'apisauce';
import {logEvent} from 'firebase/analytics';
import {getError} from '../common/errorHandling';
import {requestAuthURL, requestBaseURL, requestToken} from '../common/request';
import {analytics} from '../firebase/firebase-config';
import {userStoreTypePrimary} from '../redux/slices/user-slice/user.types';
import {store} from '../redux/store';
import {CajaEmpleado, loginPayloadType, tokenType, userType} from './dto/auth';
import {CierreZData} from './dto/cierre';
import {comanda} from './dto/comanda';
import {DataWrapper} from './dto/common';
import {compraSucursal, estadoDeCompra, proveedor} from './dto/compras';
import {
  cargo,
  EmpleadoRestaurante,
  EmpleadoSucursal,
  permisos,
} from './dto/empleados';
import {facturaSucursal} from './dto/factura';
import {
  categoriaInsumo,
  insumoSucursal,
  subCategoriaInsumo,
  unidadMedida,
} from './dto/insumo';
import {inventarioSucursal} from './dto/inventario';
import {tipoBannerRestaurante, tipoDeBanners} from './dto/marketing';
import {
  categoriaMenu,
  ProductoRestaurante,
  subcategoriaMenu,
  subcategoriaRelacion,
} from './dto/menu';
import {AccionModificador, Modificador} from './dto/modificadores';
import {
  ApodoMesa,
  clienteType,
  codigoOrdenPayload,
  Cuenta,
  cuentaPayload,
  descuentoRestaurantType,
  FacturaDirecta,
  impuestoRestaurantType,
  infoCliente,
  MenuRestaurante,
  MenuSucursal,
  MesaSucursal,
  MetodoPago,
  metodoPagoRestaurantType,
  motivoDeCancelacion,
  propinaRestaurantType,
  TipoImpuesto,
  TipoPagoDescuento,
  TipoPagoPropina,
  TipoPersona,
  ubicationType,
  VentasDeudasCliente,
} from './dto/orden';
import {cuentaOrdenIndirecta, ordenIndirecta} from './dto/orden-indirecta';
import {
  reporteArticulosBorrados,
  reporteBalanceActual,
  reporteContador,
  reporteDeVentasPorMesa,
  reporteDeVentasPorTiempo,
  reporteDeVentasPorUsuario,
  reporteFacturasAnuladas,
  reporteVentasPorArticulo,
  reporteVentasPorCategoria,
  reporteVentasPorDescuento,
  reporteVentasPorMetodoPago,
  reporteVentasPorSeccion,
  reporteVentasPorSubCategoria,
  reporteVentasRestaurante,
  reporteVentasSucursal,
} from './dto/reportes';
import {
  caja,
  empleadoAsignadoSeccion,
  restaurante,
  SeccionSucursal,
  sucursalRestaurante,
  tipoDeComida,
  tipoSeccion,
} from './dto/restaurante';
import {EntidadReceptora} from '../redux/slices/orden-slice/orden.types';

const commonReportConfigs = {
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
};
function handleResponse<T>(response: ApiResponse<T, T>) {
  if (response.ok && response?.data) {
    return response.data;
  }
  logEvent(analytics, 'exception', {
    fatal: true,
    originalError: JSON.stringify(response.originalError?.toJSON()),
    url: response.config?.url,
    errorMsg: JSON.stringify(response.data),
    user: store.getState().userSlice.userDetail?.id,
    sucursal:
      store.getState().restauranteSlice.restauranteEmpleadoSeleccionado?.id,
    sucursal_id:
      store.getState().restauranteSlice.empleadoAsignadoSucursalSeleccionado
        ?.sucursal_id,
  });
  getError(response);
  return null;
}
//ENTIDAD RECEPTORA
const getEntidadReceptora = async (
  tipo_receptor_id: number,
  buscador: string,
): Promise<DataWrapper<EntidadReceptora[]> | null> => {
  let response = await requestToken.get<DataWrapper<EntidadReceptora[]>>(
    `/entidad_receptora?tipo_receptor_id=${tipo_receptor_id}&buscador=${buscador}`,
  );
  console.log('RESPUESTA DE ER:', response);
  return handleResponse(response);
};
const postEntidadReceptora = async (
  data: any,
): Promise<DataWrapper<EntidadReceptora> | null> => {
  const tipo_receptor_id = data.tipo_receptor_id;

  if (tipo_receptor_id === '1' || tipo_receptor_id === '3') {
    delete data.info_cliente;
  }

  if (tipo_receptor_id === '2' || tipo_receptor_id === '4') {
    delete data.ruc_receptor;
  }

  let response = await requestToken.post<DataWrapper<EntidadReceptora>>(
    `/entidad_receptora`,
    data,
  );
  return handleResponse(response);
};
export const getCountryList = async (): Promise<any> => {
  const url = '/paises';
  return await requestToken.get(url);
};

export const getTipoReceptor = async (): Promise<any> => {
  const url = '/tipo_receptor';
  return await requestToken.get(url);
};

const putEntidadReceptora = async (
  dataEntidad: EntidadReceptora,
  id: number,
): Promise<DataWrapper<EntidadReceptora> | null> => {
  let response = await requestToken.post<DataWrapper<EntidadReceptora>>(
    `/entidad_receptora`,
    dataEntidad,
  );
  console.log('RESPUESTA DE PUT ER:', response);
  return handleResponse(response);
};

//FIN ENTIDAD RECEPTORA
//FIN ENTIDAD RECEPTORA

const postLogin = async (
  loginData: loginPayloadType,
): Promise<DataWrapper<userType> | null> => {
  const response = await requestAuthURL.post<DataWrapper<userType>>(
    '/login',
    loginData,
  );
  return handleResponse(response);
};

const postRegister = async (registerData: FormData): Promise<any | null> => {
  const response = await requestAuthURL.post('/sign-in', registerData);
  return handleResponse(response);
};

const getEmailValidate = async (
  pin: string,
  email: string,
): Promise<any | null> => {
  const response = await requestToken.get(
    `/validar-email?pin=${pin}&email=${email}`,
  );
  return handleResponse(response);
};

const getResendEmailValidateCode = async (
  email: string,
): Promise<any | null> => {
  const response = await requestAuthURL.get(
    `/reenviar-pin-email?email=${email}`,
  );

  return handleResponse(response);
};

const postRecoverPassword = async (data: any): Promise<any | null> => {
  const response = await requestAuthURL.post(`/refresh-password`, data);
  return handleResponse(response);
};

const postToken = async (
  login: loginPayloadType,
): Promise<tokenType | null> => {
  const grant_type = process.env.REACT_APP_GRANT_TYPE!;
  const client_id = process.env.REACT_APP_CLIENT_ID!;
  const client_secret = process.env.REACT_APP_CLIENT_SECRET!;

  const tokenBody = {
    grant_type,
    client_id,
    client_secret,
    username: login.email,
    password: login.password,
    scope: '*',
  };
  const response = await requestBaseURL.post<tokenType>(
    '/oauth/token',
    tokenBody,
  );

  return handleResponse(response);
};

const getUser = async (): Promise<DataWrapper<userType> | null> => {
  const response = await requestToken.get<DataWrapper<userType>>('/usuario');
  return handleResponse(response);
};

const postImage = async (
  formData: FormData,
  urlTransformer?: string,
): Promise<any | null> => {
  const url = urlTransformer ? `/imagen/${urlTransformer}` : '/imagen';
  const response = await requestToken.post(url, formData);
  return handleResponse(response);
};

const putUser = async (
  userID: number,
  data: userStoreTypePrimary,
): Promise<DataWrapper<userType> | null> => {
  const response = await requestToken.put<DataWrapper<userType>>(
    `/usuario/${userID}`,
    data,
  );
  return handleResponse(response);
};

const postPassword = async (password: string): Promise<any | null> => {
  const response = await requestToken.post('/update-password', {password});
  return handleResponse(response);
};

const postInvitacion = async (
  code: string,
  accion: number,
): Promise<any | null> => {
  const response = await requestToken.post('/empleado_verificacion_email', {
    code,
    accion,
  });
  return handleResponse(response);
};

// ENDPOINTS DE REPORTES

const postCierre = async (data: any): Promise<Blob | null> => {
  const response = await requestToken.post<Blob>(`/reporte_ventas`, data, {
    responseType: 'blob',
  });

  return handleResponse(response);
};

const postReporteBalanceActual = async (
  data: any,
): Promise<reporteBalanceActual | null> => {
  const response = await requestToken.post<reporteBalanceActual>(
    `/reporte_balance_actual`,

    data,
    {
      ...commonReportConfigs,
    },
  );
  console.log('PASE POR TIMEOUT');

  return handleResponse(response);
};

const postReporteVentasPorArticulo = async (
  data: any,
): Promise<reporteVentasPorArticulo | null> => {
  const response = await requestToken.post<reporteVentasPorArticulo>(
    `/reporte_ventas_por_articulo`,
    data,
    {
      ...commonReportConfigs,
    },
  );

  return handleResponse(response);
};

const downloadReporteVentasPorArticulo = async (data: any): Promise<any> => {
  const response = await requestToken.post<any>(
    `/reporte_ventas_por_articulo?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};

const postReporteArticulosBorrados = async (
  data: any,
): Promise<{data: reporteArticulosBorrados[]} | null> => {
  const reponse = await requestToken.post<{data: reporteArticulosBorrados[]}>(
    `/reportes/articulos_borrados`,
    data,
    {
      ...commonReportConfigs,
    },
  );

  return handleResponse(reponse);
};

const downloadReporteArticulosBorrados = async (data: any): Promise<any> => {
  const reponse = await requestToken.post<{data: reporteArticulosBorrados[]}>(
    `/reportes/articulos_borrados?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );
  return handleResponse(reponse);
};

const postReporteDeVentasPorMetodoPago = async (
  data: any,
): Promise<reporteVentasPorMetodoPago | null> => {
  const response = await requestToken.post<reporteVentasPorMetodoPago>(
    `/reporte_ventas_por_metodo_pago`,
    data,
    {
      ...commonReportConfigs,
    },
  );

  return handleResponse(response);
};

const downloadReporteDeVentasPorMetodoPago = async (
  data: any,
): Promise<any> => {
  const response = await requestToken.post<any>(
    `/reporte_ventas_por_metodo_pago?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};
const postReporteVentasPorTiempo = async (
  data: any,
): Promise<reporteDeVentasPorTiempo[] | null> => {
  const response = await requestToken.post<reporteDeVentasPorTiempo[]>(
    `/reporte_ventas_por_tiempo`,
    data,
    {
      ...commonReportConfigs,
    },
  );

  return handleResponse(response);
};

const downloadReporteVentasPorTiempo = async (data: any): Promise<any> => {
  const response = await requestToken.post<any>(
    `/reporte_ventas_por_tiempo?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};

const postReporteVentasPorUsuario = async (
  data: any,
): Promise<DataWrapper<reporteDeVentasPorUsuario[]> | null> => {
  const response = await requestToken.post<
    DataWrapper<reporteDeVentasPorUsuario[]>
  >(`/reporte_ventas_por_usuario`, data, {
    ...commonReportConfigs,
  });

  return handleResponse(response);
};

const downloadReporteVentasPorUsuario = async (data: any): Promise<any> => {
  const response = await requestToken.post<any>(
    `/reporte_ventas_por_usuario?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};

const postReporteVentasPorMesa = async (
  data: any,
): Promise<DataWrapper<reporteDeVentasPorMesa[]> | null> => {
  const response = await requestToken.post<
    DataWrapper<reporteDeVentasPorMesa[]>
  >(`/reporte_ventas_por_mesa`, data, {
    ...commonReportConfigs,
  });

  return handleResponse(response);
};

const postReporteVentasPorSeccion = async (
  data: any,
): Promise<reporteVentasPorSeccion[] | null> => {
  const response = await requestToken.post<reporteVentasPorSeccion[]>(
    '/reportes/ventas_por_seccion',
    data,
    {
      ...commonReportConfigs,
    },
  );

  return handleResponse(response);
};

const downloadReporteVentasPorMesa = async (data: any): Promise<any> => {
  const response = await requestToken.post<any>(
    `/reporte_ventas_por_mesa?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};

const gerReporteContadores = async (
  data: any,
): Promise<reporteContador | null> => {
  const response = await requestToken.post<reporteContador>(
    '/reportes/contador',
    data,
    {
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};

const getReporteCaja = async (
  caja: number,
): Promise<DataWrapper<CierreZData[]> | null> => {
  const response = await requestToken.get<DataWrapper<CierreZData[]>>(
    `reporte_caja?caja_sucursal_id=${caja}`,
    {
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};

const getReporteCajaSucursal = async (
  sucursal: number,
): Promise<DataWrapper<CierreZData[]> | null> => {
  const response = await requestToken.get<DataWrapper<CierreZData[]>>(
    `reporte_caja?sucursal_id=${sucursal}`,
    {
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};

const deleteReporteCaja = async (
  id: number,
): Promise<DataWrapper<CierreZData> | null> => {
  const response = await requestToken.delete<DataWrapper<CierreZData>>(
    `reporte_caja/${id}`,
    {
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};
const getReporteVentasRestaurantes = async (): Promise<DataWrapper<
  reporteVentasRestaurante[]
> | null> => {
  const response = await requestToken.get<
    DataWrapper<reporteVentasRestaurante[]>
  >(`/reporte_ventas_restaurantes`);
  return handleResponse(response);
};
const getReporteVentasSucursal = async (
  fecha_inicial: string,
  fecha_final: string,
  sucursal_id: number,
): Promise<DataWrapper<reporteVentasSucursal> | null> => {
  const response = await requestToken.get<DataWrapper<reporteVentasSucursal>>(
    `/reporte_ventas_sucursal?sucursal_id=${sucursal_id}&fecha_hora_inicial=${fecha_inicial}&fecha_hora_final=${fecha_final}`,
    {},
  );
  return handleResponse(response);
};

const postReporteVentasPorCategoria = async (
  data: any,
): Promise<reporteVentasPorCategoria[] | null> => {
  const response = await requestToken.post<reporteVentasPorCategoria[]>(
    `/reporte_ventas_por_categoria`,
    data,
    {
      ...commonReportConfigs,
    },
  );

  return handleResponse(response);
};

const downloadReporteVentasPorCategoria = async (data: any): Promise<any> => {
  const response = await requestToken.post<any>(
    `/reporte_ventas_por_categoria?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );

  return handleResponse(response);
};

const postReporteVentasPorSubCategoria = async (
  data: any,
): Promise<reporteVentasPorSubCategoria[] | null> => {
  const response = await requestToken.post<reporteVentasPorSubCategoria[]>(
    `/reportes/ventas_por_subcategoria`,
    data,
    {
      ...commonReportConfigs,
    },
  );

  return handleResponse(response);
};

const postReporteVentasPorDescuento = async (
  data: any,
): Promise<{data: reporteVentasPorDescuento[]} | null> => {
  const response = await requestToken.post<{data: reporteVentasPorDescuento[]}>(
    `/reportes/ventas_descuento`,
    data,
    {
      ...commonReportConfigs,
    },
  );
  console.log('PASE POR TIMEOUT');
  return handleResponse(response);
};

const postReporteFacturasAnuladas = async (
  data: any,
): Promise<{data: reporteFacturasAnuladas[]} | null> => {
  const response = await requestToken.post<{data: reporteFacturasAnuladas[]}>(
    `/reportes/facturas_anuladas`,
    data,
    {
      ...commonReportConfigs,
    },
  );

  return handleResponse(response);
};

const downloadReporteFacturasAnuladas = async (data: any): Promise<any> => {
  const response = await requestToken.post<any>(
    `/reportes/facturas_anuladas?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );

  return handleResponse(response);
};

const downloadReporteVentasPorSubCategoria = async (
  data: any,
): Promise<any> => {
  const response = await requestToken.post<any>(
    `/reportes/ventas_por_subcategoria?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};

const downloadReporteVentasPorDescuento = async (data: any): Promise<any> => {
  const response = await requestToken.post<any>(
    `/reportes/ventas_descuento?type_export=xlsx`,
    data,
    {
      responseType: 'blob',
      ...commonReportConfigs,
    },
  );
  return handleResponse(response);
};

// FIN DE REPORTES

const deleteNotificationMesa = async (
  id_notification: number,
): Promise<any | null> => {
  const response = await requestToken.delete(
    `/notification/${id_notification}`,
  );
  return handleResponse(response);
};

const getClientes = async (): Promise<DataWrapper<clienteType[]> | null> => {
  const response = await requestToken.get<DataWrapper<clienteType[]>>(
    '/cliente',
  );
  return handleResponse(response);
};

const postCliente = async (
  data: any,
): Promise<DataWrapper<clienteType> | null> => {
  const response = await requestToken.post<DataWrapper<clienteType>>(
    '/cliente',
    data,
  );
  return handleResponse(response);
};

const getUbicaciones = async (): Promise<DataWrapper<
  ubicationType[]
> | null> => {
  const response = await requestToken.get<DataWrapper<ubicationType[]>>(
    '/tipo_ubicacion',
  );
  return handleResponse(response);
};

const getMenuSucursal = async (
  sucursalID: number,
): Promise<DataWrapper<MenuSucursal[]> | null> => {
  const response = await requestToken.get<DataWrapper<MenuSucursal[]>>(
    `/menu_sucursal?sucursal_id=${sucursalID}`,
  );
  return handleResponse(response);
};

const putMenuSucursal = async (data: any, editId: number): Promise<any> => {
  const response = await requestToken.put(`/menu_sucursal/${editId}`, data);
  return handleResponse(response);
};

const getTipoImpuestoRestaurante = async (
  restaurantID: number,
): Promise<DataWrapper<impuestoRestaurantType[]> | null> => {
  const response = await requestToken.get<
    DataWrapper<impuestoRestaurantType[]>
  >(`/tipo_impuesto_restaurante?restaurante_id=${restaurantID}`);
  return handleResponse(response);
};

const postTipoImpuestoRestaurante = async (
  body: any,
): Promise<DataWrapper<impuestoRestaurantType> | null> => {
  const response = await requestToken.post<DataWrapper<impuestoRestaurantType>>(
    `/tipo_impuesto_restaurante`,
    body,
  );
  return handleResponse(response);
};

const putTipoImpuestoRestaurante = async (
  body: any,
  id: number,
): Promise<DataWrapper<impuestoRestaurantType> | null> => {
  const response = await requestToken.put<DataWrapper<impuestoRestaurantType>>(
    `/tipo_impuesto_restaurante/${id}`,
    body,
  );
  return handleResponse(response);
};

const deleteTipoImpuestoRestaurante = async (
  id: number,
): Promise<DataWrapper<impuestoRestaurantType> | null> => {
  const response = await requestToken.delete<
    DataWrapper<impuestoRestaurantType>
  >(`/tipo_impuesto_restaurante/${id}`);
  return handleResponse(response);
};

const getTipoPropinaRestaurante = async (
  restaurantID: number,
): Promise<DataWrapper<propinaRestaurantType[]> | null> => {
  const response = await requestToken.get<DataWrapper<propinaRestaurantType[]>>(
    `/tipo_propina_restaurante?restaurante_id=${restaurantID}`,
  );
  return handleResponse(response);
};

const postTipoPropinaRestaurante = async (
  body: any,
): Promise<DataWrapper<propinaRestaurantType> | null> => {
  const response = await requestToken.post<DataWrapper<propinaRestaurantType>>(
    `/tipo_propina_restaurante`,
    body,
  );
  return handleResponse(response);
};

const putTipoPropinaRestaurante = async (
  body: any,
  id: number,
): Promise<DataWrapper<propinaRestaurantType> | null> => {
  const response = await requestToken.put<DataWrapper<propinaRestaurantType>>(
    `/tipo_propina_restaurante/${id}`,
    body,
  );
  return handleResponse(response);
};

const deleteTipoPropinaRestaurante = async (
  id: number,
): Promise<DataWrapper<propinaRestaurantType> | null> => {
  const response = await requestToken.delete<
    DataWrapper<propinaRestaurantType>
  >(`/tipo_propina_restaurante/${id}`);
  return handleResponse(response);
};

const getTipoDescuentoRestaurante = async (
  restaurantID: number,
): Promise<DataWrapper<descuentoRestaurantType[]> | null> => {
  const response = await requestToken.get<
    DataWrapper<descuentoRestaurantType[]>
  >(`/tipo_descuento_restaurante?restaurante_id=${restaurantID}`);
  return handleResponse(response);
};

const postTipoDescuentoRestaurante = async (
  body: any,
): Promise<DataWrapper<descuentoRestaurantType> | null> => {
  const response = await requestToken.post<
    DataWrapper<descuentoRestaurantType>
  >(`/tipo_descuento_restaurante`, body);
  return handleResponse(response);
};

const putTipoDescuentoRestaurante = async (
  body: any,
  id: number,
): Promise<DataWrapper<descuentoRestaurantType> | null> => {
  const response = await requestToken.put<DataWrapper<descuentoRestaurantType>>(
    `/tipo_descuento_restaurante/${id}`,
    body,
  );
  return handleResponse(response);
};

const deleteTipoDescuentoRestaurante = async (
  id: number,
): Promise<DataWrapper<descuentoRestaurantType> | null> => {
  const response = await requestToken.delete<
    DataWrapper<descuentoRestaurantType>
  >(`/tipo_descuento_restaurante/${id}`);
  return handleResponse(response);
};

const getMetodoPagoRestaurante = async (
  restaurantID: number,
): Promise<DataWrapper<metodoPagoRestaurantType[]> | null> => {
  const response = await requestToken.get<
    DataWrapper<metodoPagoRestaurantType[]>
  >(`/metodo_pago_restaurante?restaurante_id=${restaurantID}`);
  return handleResponse(response);
};

const postMetodoPagoRestaurante = async (
  body: any,
): Promise<DataWrapper<metodoPagoRestaurantType> | null> => {
  const response = await requestToken.post<
    DataWrapper<metodoPagoRestaurantType>
  >(`/metodo_pago_restaurante`, body);
  return handleResponse(response);
};

const putMetodoPagoRestaurante = async (
  body: any,
  id: number,
): Promise<DataWrapper<metodoPagoRestaurantType> | null> => {
  const response = await requestToken.put<
    DataWrapper<metodoPagoRestaurantType>
  >(`/metodo_pago_restaurante/${id}`, body);
  return handleResponse(response);
};

const deleteMetodoPagoRestaurante = async (
  id: number,
): Promise<DataWrapper<metodoPagoRestaurantType> | null> => {
  const response = await requestToken.delete<
    DataWrapper<metodoPagoRestaurantType>
  >(`/metodo_pago_restaurante/${id}`);
  return handleResponse(response);
};

const postFacturaDirecta = async (
  data: any,
): Promise<DataWrapper<FacturaDirecta> | null> => {
  const response = await requestToken.post<DataWrapper<FacturaDirecta>>(
    '/factura_directa',
    data,
  );
  return handleResponse(response);
};

const getCajaEmpleadoSucursal = async (
  empleadoSucursalID: number,
): Promise<DataWrapper<CajaEmpleado[]> | null> => {
  const response = await requestToken.get<DataWrapper<CajaEmpleado[]>>(
    `/caja_empleado?empleado_sucursal_id=${empleadoSucursalID}`,
  );
  if (response.ok && response?.data) {
    return response.data;
  }
  return null;
};

const getMesaSucursal = async (
  sucursalId: number,
  withTrashed = false,
): Promise<DataWrapper<MesaSucursal[]> | null> => {
  const response = await requestToken.get<DataWrapper<MesaSucursal[]>>(
    `/mesa_sucursal?sucursal_id=${sucursalId}&with_trashed=${
      withTrashed ? 1 : 0
    }`,
  );
  return handleResponse(response);
};

const getApodosMesa = async (): Promise<DataWrapper<ApodoMesa[]> | null> => {
  const response = await requestToken.get<DataWrapper<ApodoMesa[]>>(
    `/apodo_mesa`,
  );
  return handleResponse(response);
};

const getCuentaSucursal = async (): Promise<DataWrapper<Cuenta[]> | null> => {
  let response = await requestToken.get<DataWrapper<Cuenta[]>>(`/cuenta`);
  return handleResponse(response);
};

const postCodigoOrden = async (
  payload: codigoOrdenPayload,
): Promise<DataWrapper<any> | null> => {
  let response = await requestToken.post<DataWrapper<any>>(
    `/codigo_orden`,
    payload,
  );
  return handleResponse(response);
};

const postCuenta = async (
  payload: cuentaPayload,
): Promise<DataWrapper<any> | null> => {
  let response = await requestToken.post<DataWrapper<any>>(`/cuenta`, payload);
  return handleResponse(response);
};

const putCuenta = async (
  id: number,
  payload: any,
): Promise<DataWrapper<any> | null> => {
  let response = await requestToken.put<DataWrapper<any>>(
    `/cuenta/${id}`,
    payload,
  );
  return handleResponse(response);
};

const getRestaurantes = async (): Promise<DataWrapper<
  restaurante[]
> | null> => {
  let response = await requestToken.get<DataWrapper<restaurante[]>>(
    `/restaurantes`,
  );
  return handleResponse(response);
};

const getRestaurante = async (
  id: number,
): Promise<DataWrapper<restaurante> | null> => {
  let response = await requestToken.get<DataWrapper<restaurante>>(
    `/restaurantes/${id}`,
  );
  return handleResponse(response);
};

const postRestaurante = async (
  formData: FormData,
): Promise<DataWrapper<restaurante> | null> => {
  let response = await requestToken.post<DataWrapper<restaurante>>(
    `/restaurantes`,
    formData,
  );
  return handleResponse(response);
};

const putRestaurante = async (
  body: any,
  id: number,
): Promise<DataWrapper<restaurante> | null> => {
  let response = await requestToken.put<DataWrapper<restaurante>>(
    `/restaurantes/${id}`,
    body,
  );
  return handleResponse(response);
};

const getProductos = async (
  id: number,
): Promise<DataWrapper<ProductoRestaurante[]> | null> => {
  let response = await requestToken.get<DataWrapper<ProductoRestaurante[]>>(
    `/producto?restaurante_id=${id}`,
  );
  return handleResponse(response);
};

const postProducto = async (
  formData: FormData,
): Promise<DataWrapper<ProductoRestaurante> | null> => {
  let response = await requestToken.post<DataWrapper<ProductoRestaurante>>(
    `/producto`,
    formData,
  );
  return handleResponse(response);
};

const putProducto = async (
  formData: FormData,
  id: number,
): Promise<DataWrapper<ProductoRestaurante> | null> => {
  formData.append('_method', 'PUT');
  let response = await requestToken.post<DataWrapper<ProductoRestaurante>>(
    `/producto/${id}`,
    formData,
  );
  return handleResponse(response);
};

const getCategorias = async (): Promise<DataWrapper<
  categoriaMenu[]
> | null> => {
  let response = await requestToken.get<DataWrapper<categoriaMenu[]>>(
    `/categoria_producto`,
  );
  return handleResponse(response);
};

const postCategorias = async (
  formData: FormData,
): Promise<DataWrapper<categoriaMenu> | null> => {
  let response = await requestToken.post<DataWrapper<categoriaMenu>>(
    `/categoria_producto`,
    formData,
  );
  return handleResponse(response);
};

const putCategorias = async (
  formData: FormData,
  id: number,
): Promise<DataWrapper<categoriaMenu> | null> => {
  let response = await requestToken.put<DataWrapper<categoriaMenu>>(
    `/categoria_producto/${id}`,
    formData,
  );
  return handleResponse(response);
};

const getSubCategorias = async (
  restaurante_id: number,
): Promise<DataWrapper<subcategoriaMenu[]> | null> => {
  let response = await requestToken.get<DataWrapper<subcategoriaMenu[]>>(
    `/subcategoria_producto?restaurante_id=${restaurante_id}`,
  );
  return handleResponse(response);
};

const postSubCategorias = async (
  data: any,
): Promise<DataWrapper<subcategoriaMenu> | null> => {
  let response = await requestToken.post<DataWrapper<subcategoriaMenu>>(
    `/subcategoria_producto`,
    data,
  );
  return handleResponse(response);
};

const putSubCategorias = async (
  data: any,
  id: number,
): Promise<DataWrapper<subcategoriaMenu> | null> => {
  let response = await requestToken.put<DataWrapper<subcategoriaMenu>>(
    `/subcategoria_producto/${id}`,
    data,
  );
  return handleResponse(response);
};

const deleteSubCategoria = async (
  id: number,
): Promise<DataWrapper<subcategoriaRelacion> | null> => {
  let response = await requestToken.delete<DataWrapper<subcategoriaRelacion>>(
    `/subcategoria_producto/${id}`,
  );
  return handleResponse(response);
};

const postMenuRestaurante = async (
  data: FormData,
): Promise<DataWrapper<MenuRestaurante> | null> => {
  let response = await requestToken.post<DataWrapper<MenuRestaurante>>(
    `/menu_restaurante`,
    data,
  );
  return handleResponse(response);
};

const putMenuRestaurante = async (
  data: FormData,
  id: number,
): Promise<DataWrapper<MenuRestaurante> | null> => {
  data.append('_method', 'PUT');
  let response = await requestToken.post<DataWrapper<MenuRestaurante>>(
    `/menu_restaurante/${id}`,
    data,
  );
  return handleResponse(response);
};

const deleteMenuRestaurante = async (
  id: number,
): Promise<DataWrapper<MenuRestaurante> | null> => {
  let response = await requestToken.delete<DataWrapper<MenuRestaurante>>(
    `/menu_restaurante/${id}`,
  );
  return handleResponse(response);
};

const getMenuRestaurante = async (
  id: number,
): Promise<DataWrapper<MenuRestaurante[]> | null> => {
  let response = await requestToken.get<DataWrapper<MenuRestaurante[]>>(
    `/menu_restaurante?restaurante_id=${id}`,
  );
  return handleResponse(response);
};

const getTipoDeComida = async (): Promise<DataWrapper<
  tipoDeComida[]
> | null> => {
  let response = await requestToken.get<DataWrapper<tipoDeComida[]>>(
    `/tipo_comidas`,
  );
  return handleResponse(response);
};

const getSucursales = async (
  id: number,
): Promise<DataWrapper<sucursalRestaurante[]> | null> => {
  let response = await requestToken.get<DataWrapper<sucursalRestaurante[]>>(
    `/sucursal_restaurante?restaurante_id=${id}`,
  );
  return handleResponse(response);
};

const getSucursal = async (
  id: number,
): Promise<DataWrapper<sucursalRestaurante> | null> => {
  let response = await requestToken.get<DataWrapper<sucursalRestaurante>>(
    `/sucursal_restaurante/${id}`,
  );
  return handleResponse(response);
};

const postSucursal = async (
  body: any,
): Promise<DataWrapper<sucursalRestaurante> | null> => {
  let response = await requestToken.post<DataWrapper<sucursalRestaurante>>(
    `/sucursal_restaurante`,
    body,
  );
  return handleResponse(response);
};

const putSucursal = async (
  body: any,
  id: number,
): Promise<DataWrapper<sucursalRestaurante> | null> => {
  let response = await requestToken.put<DataWrapper<sucursalRestaurante>>(
    `/sucursal_restaurante/${id}`,
    body,
  );
  return handleResponse(response);
};

const getSeccionSucursal = async (
  sucursalId: number,
): Promise<DataWrapper<SeccionSucursal[]> | null> => {
  let response = await requestToken.get<DataWrapper<SeccionSucursal[]>>(
    `/seccion_sucursal?sucursal_id=${sucursalId}`,
  );
  return handleResponse(response);
};

const postSeccionSucursal = async (
  body: any,
): Promise<DataWrapper<SeccionSucursal> | null> => {
  let response = await requestToken.post<DataWrapper<SeccionSucursal>>(
    `/seccion_sucursal`,
    body,
  );
  return handleResponse(response);
};

const putSeccionSucursal = async (
  body: any,
  id: number,
): Promise<DataWrapper<SeccionSucursal> | null> => {
  let response = await requestToken.put<DataWrapper<SeccionSucursal>>(
    `/seccion_sucursal/${id}`,
    body,
  );
  return handleResponse(response);
};

const deleteSeccionSucursal = async (
  id: number,
): Promise<DataWrapper<SeccionSucursal> | null> => {
  let response = await requestToken.delete<DataWrapper<SeccionSucursal>>(
    `/seccion_sucursal/${id}`,
  );
  return handleResponse(response);
};

const postMesaSucursal = async (
  body: any,
): Promise<DataWrapper<MesaSucursal> | null> => {
  const response = await requestToken.post<DataWrapper<MesaSucursal>>(
    `/mesa_sucursal`,
    body,
  );
  return handleResponse(response);
};

const getTipoImpuesto = async (): Promise<DataWrapper<
  TipoImpuesto[]
> | null> => {
  const response = await requestToken.get<DataWrapper<TipoImpuesto[]>>(
    `/tipo_impuesto`,
  );
  return handleResponse(response);
};

const getTipoDescuento = async (): Promise<DataWrapper<
  TipoPagoDescuento[]
> | null> => {
  const response = await requestToken.get<DataWrapper<TipoPagoDescuento[]>>(
    `/tipo_pago_descuento`,
  );
  return handleResponse(response);
};

const getTipoPropina = async (): Promise<DataWrapper<
  TipoPagoPropina[]
> | null> => {
  const response = await requestToken.get<DataWrapper<TipoPagoPropina[]>>(
    `/tipo_pago_propina`,
  );
  return handleResponse(response);
};

const getTipoMetodoPago = async (): Promise<DataWrapper<
  MetodoPago[]
> | null> => {
  const response = await requestToken.get<DataWrapper<MetodoPago[]>>(
    `/metodo_pago`,
  );
  return handleResponse(response);
};

const putMesaSucursal = async (
  body: any,
  id: number,
): Promise<DataWrapper<MesaSucursal> | null> => {
  const response = await requestToken.put<DataWrapper<MesaSucursal>>(
    `/mesa_sucursal/${id}`,
    body,
  );
  return handleResponse(response);
};

const deleteMesaSucursal = async (
  id: number,
): Promise<DataWrapper<MesaSucursal> | null> => {
  const response = await requestToken.delete<DataWrapper<MesaSucursal>>(
    `/mesa_sucursal/${id}`,
  );
  return handleResponse(response);
};

const getPermisos = async (): Promise<DataWrapper<permisos[]> | null> => {
  const response = await requestToken.get<DataWrapper<permisos[]>>(`/permisos`);
  return handleResponse(response);
};

const getCargos = async (id: number): Promise<DataWrapper<cargo[]> | null> => {
  const response = await requestToken.get<DataWrapper<cargo[]>>(
    `/cargo_empleados?restaurante_id=${id}`,
  );
  return handleResponse(response);
};

const postCargos = async (data: any): Promise<DataWrapper<cargo[]> | null> => {
  const response = await requestToken.post<DataWrapper<cargo[]>>(
    `/cargo_empleados`,
    data,
  );
  return handleResponse(response);
};

const putCargos = async (
  data: any,
  id: number,
): Promise<DataWrapper<cargo[]> | null> => {
  const response = await requestToken.put<DataWrapper<cargo[]>>(
    `/cargo_empleados/${id}`,
    data,
  );
  return handleResponse(response);
};

const deleteCargos = async (
  id: number,
): Promise<DataWrapper<cargo[]> | null> => {
  const response = await requestToken.delete<DataWrapper<cargo[]>>(
    `/cargo_empleados/${id}`,
  );
  return handleResponse(response);
};

const getTipoBanner = async (): Promise<DataWrapper<
  tipoDeBanners[]
> | null> => {
  const response = await requestToken.post<DataWrapper<tipoDeBanners[]>>(
    `/tipo_banner`,
  );
  return handleResponse(response);
};

const getTipoBannerRestaurante = async (
  id: number,
): Promise<DataWrapper<tipoBannerRestaurante[]> | null> => {
  const response = await requestToken.get<DataWrapper<tipoBannerRestaurante[]>>(
    `/banners?restaurante_id=${id}`,
  );
  return handleResponse(response);
};

const postTipoBannerRestaurante = async (
  data: any,
): Promise<DataWrapper<tipoBannerRestaurante[]> | null> => {
  const response = await requestToken.post<
    DataWrapper<tipoBannerRestaurante[]>
  >(`/banners`, data);
  return handleResponse(response);
};

const putTipoBannerRestaurante = async (
  id: number,
  data: FormData,
): Promise<DataWrapper<tipoBannerRestaurante> | null> => {
  data.append('_method', 'PUT');
  const response = await requestToken.post<DataWrapper<tipoBannerRestaurante>>(
    `/banners/${id}`,
    data,
  );
  return handleResponse(response);
};

const deleteTipoBannerRestaurante = async (
  id: number,
): Promise<DataWrapper<tipoBannerRestaurante[]> | null> => {
  const response = await requestToken.delete<
    DataWrapper<tipoBannerRestaurante[]>
  >(`/banners/${id}`);
  return handleResponse(response);
};

const getEmpleadoRestaurante = async (
  id: number,
): Promise<DataWrapper<EmpleadoRestaurante[]> | null> => {
  const response = await requestToken.get<DataWrapper<EmpleadoRestaurante[]>>(
    `/empleado_restaurante?restaurante_id=${id}`,
  );
  return handleResponse(response);
};

const postEmpleadoRestaurante = async (
  data: any,
): Promise<DataWrapper<EmpleadoRestaurante> | null> => {
  const response = await requestToken.post<DataWrapper<EmpleadoRestaurante>>(
    `/empleado_restaurante`,
    data,
  );
  return handleResponse(response);
};

const putEmpleadoRestaurante = async (
  data: any,
  id: number,
): Promise<DataWrapper<EmpleadoRestaurante> | null> => {
  const response = await requestToken.put<DataWrapper<EmpleadoRestaurante>>(
    `/empleado_restaurante/${id}`,
    data,
  );
  return handleResponse(response);
};

const deleteEmpleadoRestaurante = async (
  id: number,
): Promise<DataWrapper<EmpleadoRestaurante> | null> => {
  const response = await requestToken.delete<DataWrapper<EmpleadoRestaurante>>(
    `/empleado_restaurante/${id}`,
  );
  return handleResponse(response);
};

const getEmpleadoSucursal = async (
  id: number,
): Promise<DataWrapper<EmpleadoSucursal[]> | null> => {
  const response = await requestToken.get<DataWrapper<EmpleadoSucursal[]>>(
    `/empleado_asignado_sucursal?empleado_id=${id}`,
  );
  return handleResponse(response);
};

const getEmpleadoSucursalBySucursalID = async (
  id: number,
): Promise<DataWrapper<EmpleadoSucursal[]> | null> => {
  const response = await requestToken.get<DataWrapper<EmpleadoSucursal[]>>(
    `/empleado_asignado_sucursal?sucursal_id=${id}`,
  );
  return handleResponse(response);
};

const postEmpleadoSucursal = async (
  data: any,
): Promise<DataWrapper<EmpleadoSucursal> | null> => {
  const response = await requestToken.post<DataWrapper<EmpleadoSucursal>>(
    `/empleado_asignado_sucursal`,
    data,
  );
  return handleResponse(response);
};

const putEmpleadoSucursal = async (
  data: any,
  id: number,
): Promise<DataWrapper<EmpleadoSucursal> | null> => {
  const response = await requestToken.put<DataWrapper<EmpleadoSucursal>>(
    `/empleado_asignado_sucursal/${id}`,
    data,
  );
  return handleResponse(response);
};

const deleteEmpleadoSucursal = async (
  id: number,
): Promise<DataWrapper<EmpleadoSucursal> | null> => {
  const response = await requestToken.delete<DataWrapper<EmpleadoSucursal>>(
    `/empleado_asignado_sucursal/${id}`,
  );
  return handleResponse(response);
};

const getCaja = async (id: number): Promise<DataWrapper<caja[]> | null> => {
  const response = await requestToken.get<DataWrapper<caja[]>>(
    `/caja?sucursal_id=${id}`,
  );
  return handleResponse(response);
};

const postCaja = async (data: any): Promise<DataWrapper<caja[]> | null> => {
  const response = await requestToken.post<DataWrapper<caja[]>>(`/caja`, data);
  return handleResponse(response);
};

const getCajaEmpleado = async (
  id: number,
): Promise<DataWrapper<CajaEmpleado[]> | null> => {
  let response = await requestToken.get<DataWrapper<CajaEmpleado[]>>(
    `/caja_empleado?sucursal_id=${id}`,
  );
  return handleResponse(response);
};

const postCajaEmpleado = async (
  data: any,
): Promise<DataWrapper<CajaEmpleado[]> | null> => {
  const response = await requestToken.post<DataWrapper<CajaEmpleado[]>>(
    `/caja_empleado`,
    data,
  );

  return handleResponse(response);
};

const deleteCajaEmpleado = async (
  id: number,
): Promise<DataWrapper<CajaEmpleado> | null> => {
  const response = await requestToken.delete<DataWrapper<CajaEmpleado>>(
    `/caja_empleado/${id}`,
  );

  return handleResponse(response);
};

const getTipoSeccion = async (): Promise<DataWrapper<tipoSeccion[]> | null> => {
  const response = await requestToken.get<DataWrapper<tipoSeccion[]>>(
    `/tipo_seccion`,
  );

  return handleResponse(response);
};

const getComandas = async (
  sucursal_id: number,
  seccion_sucursal_id: number,
  estado_orden: number[],
  estado_comanda: number[],
  fecha_orden?: Date,
): Promise<DataWrapper<comanda[]> | null> => {
  let url = `/comandas?sucursal_id=${sucursal_id}&seccion_sucursal_id=${seccion_sucursal_id}&estado_comanda=[${estado_comanda.join(
    ',',
  )}]`;

  if (estado_orden.length > 0)
    url = `${url}&estado_orden=[${estado_orden.join(',')}]`;
  if (fecha_orden)
    url = `${url}&fecha_orden=${fecha_orden.toISOString().substring(0, 10)}`;
  const response = await requestToken.get<DataWrapper<comanda[]>>(url);
  return handleResponse(response);
};

const postComandas = async (
  data: any,
): Promise<DataWrapper<comanda> | null> => {
  const response = await requestToken.post<DataWrapper<comanda>>(
    `/comandas`,
    data,
  );
  return handleResponse(response);
};

const postNotification = async (data: any): Promise<any> => {
  const response = await requestToken.post('notificacion_polisto', data);
  return handleResponse(response);
};

const getFactura = async (id: number): Promise<Blob | null> => {
  const response = await requestToken.get<Blob>(
    `/print_factura/${id}`,
    undefined,
    {
      responseType: 'blob',
    },
  );

  return handleResponse(response);
};

const getCategoriaInsumo = async (): Promise<DataWrapper<
  categoriaInsumo[]
> | null> => {
  const response = await requestToken.get<DataWrapper<categoriaInsumo[]>>(
    `/categoria_insumo`,
  );
  return handleResponse(response);
};

const postCategoriaInsumo = async (
  data: any,
): Promise<DataWrapper<categoriaInsumo[]> | null> => {
  const response = await requestToken.post<DataWrapper<categoriaInsumo[]>>(
    `/categoria_insumo`,
    data,
  );
  return handleResponse(response);
};

const putCategoriaInsumo = async (
  data: any,
  id: number,
): Promise<DataWrapper<categoriaInsumo[]> | null> => {
  const response = await requestToken.put<DataWrapper<categoriaInsumo[]>>(
    `/categoria_insumo/${id}`,
    data,
  );
  return handleResponse(response);
};

const deleteCategoriaInsumo = async (
  id: number,
): Promise<DataWrapper<categoriaInsumo> | null> => {
  const response = await requestToken.delete<DataWrapper<categoriaInsumo>>(
    `/categoria_insumo/${id}`,
  );
  return handleResponse(response);
};

const getSubCategoriaInsumo = async (): Promise<DataWrapper<
  subCategoriaInsumo[]
> | null> => {
  const response = await requestToken.get<DataWrapper<subCategoriaInsumo[]>>(
    `/subcategoria_insumo`,
  );
  return handleResponse(response);
};

const putSubCategoriaInsumo = async (
  data: any,
  id: number,
): Promise<DataWrapper<subCategoriaInsumo[]> | null> => {
  const response = await requestToken.put<DataWrapper<subCategoriaInsumo[]>>(
    `/subcategoria_insumo/${id}`,
    data,
  );
  return handleResponse(response);
};

const deleteSubCategoriaInsumo = async (
  id: number,
): Promise<DataWrapper<subCategoriaInsumo> | null> => {
  const response = await requestToken.delete<DataWrapper<subCategoriaInsumo>>(
    `/subcategoria_insumo/${id}`,
  );
  return handleResponse(response);
};

const postSubCategoriaInsumo = async (
  data: any,
): Promise<DataWrapper<subCategoriaInsumo[]> | null> => {
  const response = await requestToken.post<DataWrapper<subCategoriaInsumo[]>>(
    `/subcategoria_insumo`,
    data,
  );
  return handleResponse(response);
};

const getInsumoSucursal = async (
  sucursal_id: number,
): Promise<DataWrapper<insumoSucursal[]> | null> => {
  const response = await requestToken.get<DataWrapper<insumoSucursal[]>>(
    `/insumo_sucursal?sucursal_id=${sucursal_id}`,
  );
  return handleResponse(response);
};

const putInsumoSucursal = async (
  data: any,
  insumoId: number,
  insumo_sucursal_id: number,
): Promise<DataWrapper<insumoSucursal> | null> => {
  data.insumo_id = insumoId;
  const response = await requestToken.put<DataWrapper<insumoSucursal>>(
    `/insumo_sucursal/${insumo_sucursal_id}`,
    data,
  );
  return handleResponse(response);
};

const postInsumoSucursal = async (
  data: any,
): Promise<DataWrapper<insumoSucursal> | null> => {
  const response = await requestToken.post<DataWrapper<insumoSucursal>>(
    `/insumo_sucursal`,
    data,
  );
  return handleResponse(response);
};

const deleteInsumoSucursal = async (id: number): Promise<any> => {
  const response = await requestToken.delete(`/insumo_sucursal/${id}`);
  return handleResponse(response);
};

const getFacturaSucursal = async (
  fecha_inicial: string,
  fecha_final: string,
  sucursal_id: number,
  estado_factura: number = 1,
  page: number = 1,
  perPage: number = 50,
): Promise<DataWrapper<facturaSucursal[]> | null> => {
  const response = await requestToken.get<DataWrapper<facturaSucursal[]>>(
    `/factura_sucursal?sucursal_id=${sucursal_id}&fecha_hora_inicial=${fecha_inicial}&fecha_hora_final=${fecha_final}&estado_factura=${estado_factura}&page=${page}&perPage=${perPage}`,
  );
  return handleResponse(response);
};

const getFacturaSucursalByCaja = async (
  fecha_inicial: string,
  fecha_final: string,
  caja_id: number,
  estado_factura: number = 1,
  page: number = 1,
  perPage: number = 50,
): Promise<DataWrapper<facturaSucursal[]> | null> => {
  const response = await requestToken.get<DataWrapper<facturaSucursal[]>>(
    `/factura_sucursal?caja_sucursal_id=${caja_id}&fecha_hora_inicial=${fecha_inicial}&fecha_hora_final=${fecha_final}&estado_factura=${estado_factura}&page=${page}&perPage=${perPage}`,
  );
  return handleResponse(response);
};

const getUnidadMedida = async (): Promise<DataWrapper<
  unidadMedida[]
> | null> => {
  const response = await requestToken.get<DataWrapper<unidadMedida[]>>(
    `/unidad_medida`,
  );
  return handleResponse(response);
};

const getInventarioSucursal = async (
  sucursalId: number,
  perPage = 0,
  fecha_inventario?: string,
): Promise<DataWrapper<inventarioSucursal[]> | null> => {
  const response = await requestToken.get<DataWrapper<inventarioSucursal[]>>(
    `/inventario_sucursal`,
    {
      sucursal_id: sucursalId,
      perPage: perPage,
      fecha_inventario: fecha_inventario,
    },
  );
  return handleResponse(response);
};

const postInventarioSucursal = async (
  data: any,
): Promise<DataWrapper<inventarioSucursal[]> | null> => {
  const response = await requestToken.post<DataWrapper<inventarioSucursal[]>>(
    `/inventario_sucursal`,
    data,
  );
  return handleResponse(response);
};

const getProveedores = async (): Promise<DataWrapper<proveedor[]> | null> => {
  const response = await requestToken.get<DataWrapper<proveedor[]>>(
    `/proveedor`,
  );
  return handleResponse(response);
};

const getEstadoDeCompra = async (): Promise<DataWrapper<
  estadoDeCompra[]
> | null> => {
  const response = await requestToken.get<DataWrapper<estadoDeCompra[]>>(
    `/estado_compra`,
  );
  return handleResponse(response);
};

const getComprasSucursal = async (
  sucursal_id: number,
  fecha_compra_inicial: string,
  fecha_compra_final: string,
): Promise<DataWrapper<compraSucursal[]> | null> => {
  const response = await requestToken.get<DataWrapper<compraSucursal[]>>(
    `/compra_sucursal`,
    {
      sucursal_id: sucursal_id,
      fecha_compra_inicial: fecha_compra_inicial,
      fecha_compra_final: fecha_compra_final,
    },
  );
  return handleResponse(response);
};

const postComprasSucursal = async (
  data: any,
): Promise<DataWrapper<compraSucursal> | null> => {
  const response = await requestToken.post<DataWrapper<compraSucursal>>(
    `/compra_sucursal`,
    data,
  );
  return handleResponse(response);
};

const putComprasSucursal = async (
  data: any,
  compraSucursalId: number,
): Promise<DataWrapper<compraSucursal> | null> => {
  const response = await requestToken.put<DataWrapper<compraSucursal>>(
    `/compra_sucursal/${compraSucursalId}`,
    data,
  );
  return handleResponse(response);
};

const postProveedor = async (
  data: any,
): Promise<DataWrapper<proveedor> | null> => {
  const response = await requestToken.post<DataWrapper<proveedor>>(
    `/proveedor`,
    data,
  );
  return handleResponse(response);
};

const putProveedor = async (
  data: any,
  proveedorId: number,
): Promise<DataWrapper<proveedor> | null> => {
  const response = await requestToken.put<DataWrapper<proveedor>>(
    `/proveedor/${proveedorId}`,
    data,
  );
  return handleResponse(response);
};
// ENDPOINTS DE REPORTES

const putAnularFactura = async (
  id: number,
  restaurante_id: number,
): Promise<null | null> => {
  const response = await requestToken.put<null>(`/factura_sucursal/${id}`, {
    estado_factura: '0',
    restaurante_id: restaurante_id,
  });
  return handleResponse(response);
};

const getEmpleadoAsignadoSeccion = async (
  sucursalId: number,
): Promise<DataWrapper<empleadoAsignadoSeccion[]> | null> => {
  const response = await requestToken.get<
    DataWrapper<empleadoAsignadoSeccion[]>
  >(`/empleado_sucursal_area?sucursal_id=${sucursalId}`);
  return handleResponse(response);
};

const postEmpleadoAsignadoSeccion = async (
  data: any,
): Promise<DataWrapper<empleadoAsignadoSeccion> | null> => {
  const response = await requestToken.post<
    DataWrapper<empleadoAsignadoSeccion>
  >(`/empleado_sucursal_area`, data);
  return handleResponse(response);
};

const deleteEmpleadoAsignadoSeccion = async (
  id: number,
): Promise<DataWrapper<empleadoAsignadoSeccion> | null> => {
  const response = await requestToken.delete<
    DataWrapper<empleadoAsignadoSeccion>
  >(`/empleado_sucursal_area/${id}`);
  return handleResponse(response);
};

const postFacturaIndirectaInit = async (
  data: any,
): Promise<DataWrapper<cuentaOrdenIndirecta> | null> => {
  const response = await requestToken.post<DataWrapper<cuentaOrdenIndirecta>>(
    '/factura_indirecta/init',
    data,
  );
  return handleResponse(response);
};
const postIndirectaAddProduct = async (
  data: any,
): Promise<DataWrapper<cuentaOrdenIndirecta> | null> => {
  const response = await requestToken.post<DataWrapper<cuentaOrdenIndirecta>>(
    'factura_indirecta/add_product',
    data,
  );
  return handleResponse(response);
};

const putIndirectaActualizarProduct = async (
  data: any,
): Promise<DataWrapper<cuentaOrdenIndirecta> | null> => {
  const response = await requestToken.put<DataWrapper<cuentaOrdenIndirecta>>(
    'factura_indirecta/update_product',
    data,
  );
  return handleResponse(response);
};

const deleteIndirectaBorrarProduct = async (
  data: any,
): Promise<DataWrapper<cuentaOrdenIndirecta> | null> => {
  data['_method'] = 'DELETE';
  const response = await requestToken.post<DataWrapper<cuentaOrdenIndirecta>>(
    'factura_indirecta/delete_product',
    data,
  );
  return handleResponse(response);
};
const getCuenta = async (
  id: number,
): Promise<DataWrapper<cuentaOrdenIndirecta> | null> => {
  const response = await requestToken.get<DataWrapper<cuentaOrdenIndirecta>>(
    `/cuenta/${id}`,
  );
  return handleResponse(response);
};

const postFacturaIndirecta = async (
  data: any,
): Promise<DataWrapper<ordenIndirecta> | null> => {
  const response = await requestToken.post<DataWrapper<ordenIndirecta>>(
    `factura_indirecta/factura_cliente`,
    data,
  );
  return handleResponse(response);
};

const getMotivoDeCancelacion = async (): Promise<DataWrapper<
  motivoDeCancelacion[]
> | null> => {
  const response = await requestToken.get<DataWrapper<motivoDeCancelacion[]>>(
    `/motivo_cancelacion`,
  );
  return handleResponse(response);
};

///consegui el request!!! je je
const putFacturaPreCuenta = async (
  data: any,
): Promise<DataWrapper<cuentaOrdenIndirecta> | null> => {
  const response = await requestToken.put<DataWrapper<cuentaOrdenIndirecta>>(
    `/factura_indirecta/pre_cuenta`,
    data,
  );
  return handleResponse(response);
};

const getPreFactura = async (data: any): Promise<Blob | null> => {
  const response = await requestToken.post<Blob>(`/print_prefactura`, data, {
    responseType: 'blob',
  });

  return handleResponse(response);
};

const deleteClient = async (id: number, data: any): Promise<true | null> => {
  const response = await requestToken.delete<true>(
    `/factura_indirecta/delete_cuenta/${id}`,
    data,
  );
  return handleResponse(response);
};

const postMoveProduct = async (data: any): Promise<true | null> => {
  const response = await requestToken.post<true>(
    `/factura_indirecta/unir_cuenta_cliente`,
    data,
  );
  return handleResponse(response);
};

const postMoveClient = async (data: any): Promise<true | null> => {
  const response = await requestToken.post<true>(
    `/factura_indirecta/unir_mesas`,
    data,
  );
  return handleResponse(response);
};

const postMoveProducts = async (data: any): Promise<any | null> => {
  const response = await requestToken.post<any>(
    `/factura_indirecta/mover_p_cliente`,
    data,
  );
  return handleResponse(response);
};

const getAccionModificador = async (): Promise<DataWrapper<
  AccionModificador[]
> | null> => {
  const response = await requestToken.get<DataWrapper<AccionModificador[]>>(
    `accion_modificador`,
  );
  return handleResponse(response);
};

const getModificador = async (
  restaurante_id: number,
): Promise<DataWrapper<Modificador[]> | null> => {
  const response = await requestToken.get<DataWrapper<Modificador[]>>(
    `modificadores?restaurante_id=${restaurante_id}`,
  );
  return handleResponse(response);
};

const postModificador = async (
  data: any,
): Promise<DataWrapper<Modificador> | null> => {
  const response = await requestToken.post<DataWrapper<Modificador>>(
    `modificadores`,
    data,
  );
  return handleResponse(response);
};

const putModificador = async (
  data: any,
  id: number,
): Promise<DataWrapper<Modificador> | null> => {
  const response = await requestToken.put<DataWrapper<Modificador>>(
    `modificadores/${id}`,
    data,
  );
  return handleResponse(response);
};

const deleteModificador = async (
  id: number,
): Promise<DataWrapper<Modificador> | null> => {
  const response = await requestToken.delete<DataWrapper<Modificador>>(
    `modificadores/${id}`,
  );
  return handleResponse(response);
};

const getInfoClientes = async (
  num_id: string,
): Promise<DataWrapper<infoCliente[]> | null> => {
  const reponse = await requestToken.get<DataWrapper<infoCliente[]>>(
    `/info_cliente?perPage=20&num_id=${num_id}`,
  );
  return handleResponse(reponse);
};

const getInfoClientesByRestaurant = async (
  restaurant_id: number,
  page: number,
): Promise<DataWrapper<infoCliente[]> | null> => {
  const reponse = await requestToken.get<DataWrapper<infoCliente[]>>(
    `/info_cliente?perPage=20&restaurante_id=${restaurant_id}&page=${page}`,
  );
  return handleResponse(reponse);
};

const getDeudasClientesByRestaurant = async (
  restaurant_id: number,
): Promise<DataWrapper<VentasDeudasCliente[]> | null> => {
  const reponse = await requestToken.post<DataWrapper<VentasDeudasCliente[]>>(
    `/reportes/ventas_deudas_cliente`,
    {
      restaurante_id: restaurant_id,
      nombre: 'DESC',
    },
  );
  return handleResponse(reponse);
};

const updateDeudasCliente = async (
  inf_client_rest_id: number,
  total: number,
): Promise<any | null> => {
  const reponse = await requestToken.post<any>(`/balance_cliente_rest`, {
    inf_client_rest_id,
    total,
  });
  return handleResponse(reponse);
};

const getTipoPersona = async (): Promise<DataWrapper<TipoPersona[]> | null> => {
  const response = await requestToken.get<DataWrapper<TipoPersona[]>>(
    '/tipo_persona',
  );
  return handleResponse(response);
};

const postInfoClientes = async (data: {
  num_id: string;
  nombre: string;
  contacto: string;
  restaurante_id: number | undefined;
  tipoContacto: number;
}): Promise<DataWrapper<infoCliente> | null> => {
  const request = {
    ...data,
    contactos: [
      {
        contacto_id: data.tipoContacto,
        num_contacto: data.contacto,
      },
    ],
  };
  const reponse = await requestToken.post<DataWrapper<infoCliente>>(
    '/info_cliente',
    request,
  );
  return handleResponse(reponse);
};

const putInfoCliente = async (
  data: any,
): Promise<DataWrapper<infoCliente> | null> => {
  const response = await requestToken.put<DataWrapper<infoCliente>>(
    `/info_cliente/${data.id_info_cliente}`,
    data,
  );
  return handleResponse(response);
};

export const api = {
  postLogin,
  postToken,
  getUser,
  postRegister,
  getEmailValidate,
  getResendEmailValidateCode,
  postImage,
  putUser,
  postPassword,
  postInvitacion,
  getClientes,
  getMesaSucursal,
  postCliente,
  getUbicaciones,
  getMenuSucursal,
  getTipoDescuentoRestaurante,
  postTipoDescuentoRestaurante,
  putTipoDescuentoRestaurante,
  getTipoImpuestoRestaurante,
  postTipoImpuestoRestaurante,
  putTipoImpuestoRestaurante,
  getTipoPropinaRestaurante,
  postTipoPropinaRestaurante,
  putTipoPropinaRestaurante,
  getMetodoPagoRestaurante,
  postMetodoPagoRestaurante,
  putMetodoPagoRestaurante,
  postFacturaDirecta,
  getCajaEmpleadoSucursal,
  getCuentaSucursal,
  postCodigoOrden,
  postCuenta,
  getRestaurantes,
  postRestaurante,
  putRestaurante,
  putProducto,
  postProducto,
  postCategorias,
  getCategorias,
  putCategorias,
  postSubCategorias,
  putSubCategorias,
  getMenuRestaurante,
  postMenuRestaurante,
  putMenuRestaurante,
  getSucursales,
  getProductos,
  getTipoDeComida,
  getRestaurante,
  postSucursal,
  putSucursal,
  getSeccionSucursal,
  postSeccionSucursal,
  putSeccionSucursal,
  putMesaSucursal,
  postMesaSucursal,
  getTipoImpuesto,
  getTipoMetodoPago,
  getTipoDescuento,
  getTipoPropina,
  deleteMetodoPagoRestaurante,
  deleteTipoDescuentoRestaurante,
  deleteTipoImpuestoRestaurante,
  deleteTipoPropinaRestaurante,
  getPermisos,
  getCargos,
  deleteSubCategoria,
  getTipoBanner,
  getTipoBannerRestaurante,
  putTipoBannerRestaurante,
  postTipoBannerRestaurante,
  postCargos,
  putCargos,
  deleteMenuRestaurante,
  deleteCargos,
  getEmpleadoRestaurante,
  putEmpleadoRestaurante, ///url
  postEmpleadoRestaurante,
  getEmpleadoSucursal,
  postEmpleadoSucursal,
  putEmpleadoSucursal, ///url
  deleteEmpleadoSucursal,
  getCaja,
  postCaja,
  getCajaEmpleado,
  postCajaEmpleado,
  deleteCajaEmpleado,
  deleteEmpleadoRestaurante,
  getEmpleadoSucursalBySucursalID,
  getSucursal,
  getTipoSeccion,
  getComandas,
  postComandas,
  postCierre,
  getFacturaSucursal,
  getFactura,
  getCategoriaInsumo,
  getSubCategoriaInsumo,
  getInsumoSucursal,
  putInsumoSucursal,
  postInsumoSucursal,
  putCategoriaInsumo,
  postCategoriaInsumo,
  putSubCategoriaInsumo,
  postSubCategoriaInsumo,
  deleteSubCategoriaInsumo,
  deleteCategoriaInsumo,
  getUnidadMedida,
  getInventarioSucursal,
  postInventarioSucursal,
  getProveedores,
  getEstadoDeCompra,
  getComprasSucursal,
  postComprasSucursal,
  putComprasSucursal,
  postProveedor,
  putProveedor,
  getFacturaSucursalByCaja,
  postReporteBalanceActual,
  postReporteVentasPorArticulo,
  postReporteDeVentasPorMetodoPago,
  putAnularFactura,
  getEmpleadoAsignadoSeccion,
  postEmpleadoAsignadoSeccion,
  deleteEmpleadoAsignadoSeccion,
  postFacturaIndirectaInit,
  getCuenta,
  postIndirectaAddProduct,
  postFacturaIndirecta,
  putIndirectaActualizarProduct,
  deleteMesaSucursal,
  deleteIndirectaBorrarProduct,
  getMotivoDeCancelacion,
  putFacturaPreCuenta,
  getEntidadReceptora,
  postEntidadReceptora,
  putEntidadReceptora,
  getPreFactura,
  deleteClient,
  deleteSeccionSucursal,
  postMoveProduct,
  postMoveClient,
  postRecoverPassword,
  getReporteCaja,
  getAccionModificador,
  getModificador,
  postModificador,
  putModificador,
  deleteReporteCaja,
  getSubCategorias,
  deleteModificador,
  getReporteVentasRestaurantes,
  getReporteVentasSucursal,
  postReporteVentasPorCategoria,
  postReporteVentasPorTiempo,
  postReporteVentasPorUsuario,
  postReporteVentasPorMesa,
  putCuenta,
  deleteTipoBannerRestaurante,
  putMenuSucursal,
  postReporteVentasPorSubCategoria,
  postReporteArticulosBorrados,
  downloadReporteArticulosBorrados,
  downloadReporteVentasPorSubCategoria,
  postReporteFacturasAnuladas,
  postReporteVentasPorDescuento,
  downloadReporteVentasPorDescuento,
  downloadReporteFacturasAnuladas,
  downloadReporteVentasPorCategoria,
  downloadReporteVentasPorUsuario,
  downloadReporteVentasPorTiempo,
  downloadReporteVentasPorMesa,
  downloadReporteVentasPorArticulo,
  downloadReporteDeVentasPorMetodoPago,
  deleteInsumoSucursal,
  getReporteCajaSucursal,
  postReporteVentasPorSeccion,
  getApodosMesa,
  deleteNotificationMesa,
  getInfoClientes,
  postInfoClientes,
  postNotification,
  postMoveProducts,
  gerReporteContadores,
  getTipoPersona,
  getTipoReceptor,

  getInfoClientesByRestaurant,
  putInfoCliente,
  getDeudasClientesByRestaurant,
  updateDeudasCliente,
};
