import {Grid, Card, CardContent, Divider} from '@mui/material';
import dayjs from 'dayjs';
import {comanda} from '../../services/dto/comanda';
import utc from 'dayjs/plugin/utc';
import ComandaHeading from './comanda-heading';
import ComandaDetails from './comanda-details';
import ComandaPlatosList from './comanda-plato-list';
dayjs.extend(utc);

type PropsTypes = {
  comanda: comanda;
};

function ComandaItem({comanda}: PropsTypes) {
  return (
    <Grid xs={12} sm={6} md={4} lg={3} xl={3} item>
      <Card
        raised
        sx={{
          borderRadius: '15px',
          boxShadow: '10px 10px 17px -11px rgba(0,0,0,0.75)',
          transition: 'all 400ms ease',
        }}>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            width: '100%',
            p: 1,
            '&:last-child': {
              pb: 2,
            },
          }}>
          <ComandaHeading
            orden={comanda.id_codigo_orden}
            estado={comanda.estado_orden_id == 2}
            ubicacion={
              comanda.cuenta?.ubicacion.tipo_ubicacion.nombre_ubicacion ?? ''
            }
            numeroMesa={comanda?.cuenta?.ubicacion?.ubicacion?.numero_mesa}
            apodoMesa={comanda?.cuenta?.ubicacion?.ubicacion?.apodo?.nombre}
          />
          <ComandaDetails
            cuenta={comanda.cuenta_id ?? 0}
            date={comanda.created_at}
            meseroName={`${comanda.cuenta?.host.empleado?.usuario.name ?? ''} ${
              comanda.cuenta?.host.empleado?.usuario.lastname ?? ''
            }`}
            numeroMesa={comanda?.cuenta?.ubicacion?.ubicacion?.numero_mesa}
          />
          <Divider sx={{border: '0.5px solid black'}} />
          <ComandaPlatosList
            ordenes={comanda.ordenes}
            id_codigo_orden={comanda.id_codigo_orden}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ComandaItem;
